#register {
  box-sizing: content-box;

  .el-col {
    @include rtl-sass-value(float, left, right);
  }

  .step_tab_box {
    width: 100%;
    margin-bottom: 35px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 19.6%);
    column-gap: 0.5%;

    .tab_item {
      height: 32px;

      p {
        font-weight: 600;
        color: #999ea9;
        padding-bottom: 12px;
      }

      .p_wrapper {
        width: 70%;
      }

      &.active {
        p {
          color: $primary-purple;
          border-bottom: solid 3px $primary-purple;
        }
      }

      &.finish {
        p {
          color: $primary-purple;
          &:after {
            padding-left: 5px;
            content: url(~@/assets/images/register/icons-tick.png);
          }
        }
      }
    }
  }

  .main {
    strong {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.42;
      color: $primary-purple;
    }

    .form {
      margin-top: 30px;
    }

    .btn_box {
      text-align: center;
    }
  }
}

@media (max-width: 1199px) {
  #register .inner /deep/ .progress_box {
    display: none;
  }
}

@media (max-width: 767px) {
  .wrapper .content_box {
    padding-top: 0;
  }

  #register {
    .step_tab_box {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

      .tab_item {
        height: unset;

        p {
          border-bottom: solid 1px #cccfd4;
          position: relative;
          margin-bottom: 25px;
          padding-bottom: 25px;
        }

        &:first-child p {
          padding-top: 25px;
          border-top: solid 1px #cccfd4;
        }

        &.finish p::after {
          position: absolute;
          right: 0;
        }
      }
    }
    /deep/ .form_box {
      .main {
        padding: 32px 24px 40px;

        strong {
          font-size: 20px;
        }

        .form {
          margin-top: 40px;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  #register {
    .step_tab_box {
      .tab_item {
        .p_wrapper {
          width: 100%;
        }
      }
    }
  }
}
